import logo from './logo.svg';
import './App.css';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'bootstrap/dist/css/bootstrap.min.css'
import {authentication} from './firebase';
import {BrowserRouter as Router, Switch, Route, useNavigate, useLocation} from "react-router-dom";
import {Image, Form, Button, InputGroup, Alert, Card, Breadcrumb, Container, Row, Col, Modal} from 'react-bootstrap'
import Carer from "./Carer";
import Login from "./Login";
import Meds from "./Meds";
import Food from "./Food";
import Mood from "./Mood";
import Moodform from "./Moodform";
import Sleep from "./Sleep";
import Concerns from "./Concerns";
import Events from "./Events";
import Tasks from "./Tasks";
import Children from "./Children";
import CarerReview from "./CarerReview";
import Report from "./Report";
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'bootstrap-css-only/css/bootstrap.min.css';
import 'mdbreact/dist/css/mdb.css';
import { Redirect, useHistory } from "react-router-dom";
// import {useHistory } from "react-router-dom"; //useNavigate
import { getAuth, createUserWithEmailAndPassword, signInWithEmailAndPassword, onAuthStateChanged } from 'firebase/auth';
import app from './firebase'
import { useState, useEffect } from 'react';
import { role, authorizedd, user_id, ose } from './Login';
import Tutor from './Tutor';
import Admin from './Admin';
import Progress from './Progress';
import AllCourses from './AllCourses';
import AllClasssesAdmin from './AllClassesAdmin';
import AllCoursesAdmin from './AllCoursesAdmin';
import {i} from './Carer'

export var user, authorized, oseapp
const db = firebase.firestore();
var userRef = db.collection('users')
// let authorized
var log
var landed
var shower



function App() {
  // const auth = getAuth(app);
  const history = useHistory();
  const auth = getAuth();
  // const [email, setEmail] = useState("")
  // const [password, setPassword] = useState("")
  // const [username, setUsername] = useState("")
  var [user_i, setUser] = useState(null);
  var [authorizeddd, setAuthorized] = useState(false);
  var [show, setShow]=useState(false);
  var [locationing, setLocation]=useState(false);

  oseapp = ose
  console.log('oseapp: ', ose)
  user = user_i
  authorized = authorizeddd
  document.body.style = 'background: #C5F3FF;';
  // shower = 1 + i


  console.log('i: ', i)
  // const signUp = () => {
  //   createUserWithEmailAndPassword(auth, email, password)
  //   .then((userCredential)=>{
  //     // signed up
  //     const user = userCredential.user
  //     console.log('user: ', user)

  //         var docData = {
  //           userName: username,
  //           userID: user.uid
  //         }

  //         // console.log(docData)
  //         db.collection('users').doc().set(docData).then(() => {
  //           alert('Your account has been successfully created')
  //       });
      

  //     // TODO: redirect to careers
  //   })
  //   // .catch((error)=>{
  //   //   const errorCode = error.code;
  //   //   const errorMessage = error.message
  //   //   alert(errorCode)
  //   // })
  // }


  // const signIn = () => {
  //     signInWithEmailAndPassword(auth, email, password)
  //   .then((userCredential)=>{
  //     // signed in
  //     const user = userCredential.user.uid;
  //     setUser(user)
  //     authorized=true
  //     // console.log(user)
  //     history.push('/home');
  //     // alert('Successfully signed in')
  //     // 
  //   })
  //   .catch((error)=>{
  //     const errorCode = error.code;
  //     const errorMessage = error.message
  //     alert(errorMessage)
  //   })
  // }

  function HeaderView() {
    const location = useLocation();
    console.log(location.pathname);
    // return <span>Path : {location.pathname}</span>
  }

  const location = useLocation();
  useEffect(() => {
    // window.location.replace("http://localhost:3000/login");
    console.log(location.pathname);
    setLocation(location.pathname)
}, [locationing]);

  // if (locationing == '/'){
  //   window.location.replace("http://localhost:3000/login")
  // }


  setTimeout(function () {
    console.log("Delayed for 5 second."); 
    if (locationing == '/'){
      window.location.replace("https://tvctraining.co.uk/login");
    }  }, 500);

  //   window.onload = function() {
  //     // similar behavior as an HTTP redirect
  //     window.location.replace("http://localhost:3000/login");
  // }

  if (user != null){
    log = 'Logout'
    shower = false
    // setShow(false)
  }else{
    log = 'Login'
    shower = false
    // HeaderView()
    // setShow(true)
    // window.location.replace("http://localhost:3000/login");
  }

  // if (i == false){
  //   shower = true
  // }else{
  //   shower = false
  // }


  // useEffect(() => {
  //     window.location.replace("http://localhost:3000/login");
  //     }, []);
  
  useEffect(() => {
    // getLifes()
    //     history.push('/login');
    // window.location.reload();
    onAuthStateChanged(auth, (user) => {
      // window.location.replace("http://localhost:3000/login");
      // setShow(false)
      // console.log('ose', ose)
      // window.location.replace("http://localhost:3000/login");
      if (user) {
        // window.location.replace("http://localhost:3000/login");
        console.log('userLanding:', user.uid)
        setUser(user.uid)
        setShow(false)
        setAuthorized(true)
        // history.push('/login');
        // window.location.reload();
       
        // setTwitterName(user.displayName)
        // setLife(window.sessionStorage.getItem("live"));
      }
      // openRoute(<Welcome />)
    });
  }, []);
  
  // history.push("/login")

  // if (!authorized){
  //       history.push("/login")
  //   } 
    // else {
    //   history.push("/carer")
    // }

  // useEffect(() => {
  //   goTo()
  //   // history.push('/login');
  //   // window.location.reload();
  // }, [shower]);

  function goTo(){
    window.location.replace("https://tvctraining.co.uk/login")
  }

  console.log('show1: ', show)
  
  function startLearning(){
    setShow(false)
    shower = false
    console.log('show: ', show)
    // i = 2
    window.location.replace("https://tvctraining.co.uk/login");
  }
  
  function logCommand(){
    if (log == 'Logout'){
      firebase.auth().signOut()
    }
  }

  function goHome(){
    window.history.back()
  }

  return (
     <div className="App">
    {/* <div className="App"> */}
      <nav class="navbar navbar-expand navbar-light" style={{backgroundColor: '#67DFFF'}} >
        <a onClick={goHome} class="navbar-brand">
        <img src="./LOGO.png" alt="" width="100" height="40"/>
        </a>
        <button
          // onClick={() => {navigateToCarer()}}
          class="navbar-toggler"
          type ="button"
          data-bs-toggle="collapse"
          data-bs-target="#toggleMobileMenu"
          aria-controls="toggleMobileMenu"
          aria-expanded="false"
          aria-label="Toggle navigation"
          >
          <span class="navbar-toggler-icon"></span>
        </button>

        <div class="collapse navbar-collapse" id="toggleMobileMenu">
          <ul class="navbar-nav">
            {/* <li>
              <a class="nav-link" href="#">About</a>
            </li> */}
          </ul>
        </div>
        <span class="navbar-text">
         <a class="nav-link" style={{color:'#FFFFFF'}} onClick= {() => logCommand()} href="https://tvctraining.co.uk/login">{log}</a>
        </span> 
      </nav>

 
      
      {/* {show && */}
        {/* <Container className="py-5">
        <Row>
          <Col lg={6} md={6} sm={12} className="d-flex align-items-center">
            <div className="text-lg-start text-md-start text-center">
              <h1 className="font-primary color-primary">
                Feel in every touch
              </h1>
              <p className="font-secondary color-secondary py-3">
                It is a long established fact that a reader will be distracted
                by the readable content of a page when looking at its layout.{" "}
              </p>
          <Button style={{ margin: 25, borderRadius:50}} variant="primary" onClick={startLearning}>
           Start Learning
          </Button>            
          </div>
          </Col>
          <Col lg={6} md={6} className="d-none d-lg-block">
            <Image src="./LOGO.png" fluid />
          </Col>
        </Row>
      </Container> */}
      {/* } */}

      {/* <input type={"email"} placeholder="please enter your email" onChange={(e) => setEmail(e.target.value)}/>
      <input type={"password"} placeholder="please enter your password" onChange={(e) => setPassword(e.target.value)}/>
      <input type={"username"} placeholder="please enter your username" onChange={(e) => setUsername(e.target.value)}/> */}

{/* <div>
      <button onClick={signUp}>Create Account</button>
</div>
<div>
      <button onClick={signIn}>Sign in</button>
</div> */}
      

      {/* <Router>
        <Switch>
          <Route exact path="/login" component={Login} />
          <Route exact path="/carer" component={() => <Carer authorized={true}/>} />
        </Switch>
      </Router> */}

    {/* </div> */}

    <Router>
        <Switch>
          <Route exact path="/login" component={Login} />
          {/* <Route exact path="/children" component={() => <Children authorized={true}/>}/> */}
          <Route exact path="/home" component={() => <Carer authorized={authorizeddd}/>} />
          <Route exact path="/tutor" component={() => <Tutor authorized={authorizeddd}/>} />
          <Route exact path="/admin" component={() => <Admin authorized={authorizeddd}/>} />
          {/* <Route exact path="/meds" component={() => <Meds authorized={true}/>} /> */}
          <Route exact path="/scheduleclass" component={() => <Food authorized={true}/>} />
          {/* <Route exact path="/mood" component={() => <Mood authorized={true}/>} /> */}
          <Route exact path="/allclasses" component={() => <Moodform authorized={true}/>} />
          <Route exact path="/allclassesadmin" component={() => <AllClasssesAdmin authorized={true}/>} />
          <Route exact path="/allcourses" component={() => <AllCourses authorized={true}/>} />
          <Route exact path="/allcoursesadmin" component={() => <AllCoursesAdmin authorized={true}/>} />
          {/* <Route exact path="/sleep" component={() => <Sleep authorized={true}/>} /> */}
          <Route exact path="/userclasses" component={() => <Tasks authorized={true}/>} />
          <Route exact path="/instructorclasses" component={() => <Events authorized={true}/>} />
          <Route exact path="/progress" component={() => <Progress authorized={true}/>}/>
          <Route exact path="/createcourses" component={() => <Concerns authorized={true}/>}/>
          {/* <Route exact path="/carerreview" component={() => <CarerReview authorized={true}/>}/> */}
          {/* <Route exact path="/report" component={() => <Report authorized={true}/>}/> */}
          
        </Switch>
      </Router>
{/* 
      {shower &&
        <Container  style={{ marginTop: 50}} className="py-5">
        <Row>
          <Col lg={6} md={6} sm={12} className="d-flex align-items-center">
            <div className="text-lg-start text-md-start text-center">
              <h1 className="font-primary color-primary">
              Empowering Care through Quality Training
              </h1>
              <p className="font-secondary color-secondary py-3">
              At Tees-Valley Care, we provide comprehensive training to carers 
              who are committed to giving compassionate care to their clients.{" "}
              </p>
          <Button style={{ margin: 25, borderRadius:50}} variant="primary" onClick={startLearning}>
           Start Learning
          </Button>            
          </div>
          </Col>
          <Col lg={6} md={6} className="d-none d-lg-block">
            <Image src="./tvcimg.jpg" fluid />
          </Col>
        </Row>
      </Container> 
      } */}
      </div>
  );
}

export default App;