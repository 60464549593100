import React, {useState, useEffect} from "react";
import { Redirect } from "react-router-dom";
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import {FloatingLabel, Spinner, Accordion, Form, InputGroup, Alert, Card, Breadcrumb, Container, Row, Col, Modal} from 'react-bootstrap'
import firebase from 'firebase/compat/app';
import user from './App';
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";


let varia
const db = firebase.firestore();

function Progress ({ authorized }) {
  const [users, setUsers] = useState([]);
  const [isFetching, setIsFetching] = useState(true); 
  const [houseOption, setHouseOption]=useState('FH');


  useEffect(() => {
    setTimeout(function () {
      console.log("Delayed for 5 second."); 
      setIsFetching(false); 
    }, 500);
  }, []);


  // get courses on page load
  useEffect(() => {
    getUsers()
    },[houseOption])

    if (isFetching) {
      return (
        <Box sx={{ display: "flex", justifyContent: "center" }}>
        <CircularProgress />
      </Box>
      );
    }

    if (!authorized){
        return <Redirect to="/login" />;
      }


      function getUsers(){
      db.collection("users").where('house', "==", houseOption)
      .get()
      .then((snapshot) => {
        if (snapshot.empty) {
          alert('Sorry no users available at the moment')
        }else{
          console.log(snapshot.docs.map(doc => doc.data()))
          setUsers(snapshot.docs.map(doc => doc.data()));
        }
       });
      }

      function getHouseOption(houseOption)
      {
          setHouseOption(houseOption.target.value)
      }

  return (
    <div>
        <FloatingLabel style={{ color: '#0020BC', margin:10, marginTop: 20, marginBottom: 5}} controlId="floatingSelect" label="click here to select a TVC house">
        <Form.Select style={{ color: '#0020BC'}} onChange={getHouseOption}  aria-label="Default select example">
                <option>Select House</option>
                <option value="FH">Farm House</option>
                <option value="WH">Wood House</option>
                <option value="CH">Carney House</option>
                <option value='AH'>Alexander House</option>
                {/* <option value="allhouses">All Houses</option> */}
                <option value="nontvc">Non TVC Staff</option>
        </Form.Select>
        </FloatingLabel>

        <Row xs={1} md={2} className="g-2" style={{ margin: 10, marginTop: 10}}>
        {users.map((user, id) => (
          <Col>
            <Card variant="light" style={{ backgroundColor: '#F4FFF9', borderRadius:15, margin: 10, marginTop: 40, padding:20}}  className="d-grid gap-2">
            <div><b class="mb-1"> {user.userName} </b> <small class="text-muted">  </small></div>
            <div><b class="mb-1"> </b> <small class="text-muted"> House: {user.house} </small></div>
            <Accordion defaultActiveKey="0" style={{ margin: 10}}>
              <Accordion.Item eventKey="0">
                <Accordion.Header>view progress</Accordion.Header>
                <Accordion.Body>
                { 
                  user.coursesAttended.map((coursesAttended, id) => (
                  <Card style={{ margin: 10, padding: 20}}>
                  <div>
                  <b class="mb-1"> </b> <small class="text-muted"> {coursesAttended}
                </small>
                </div>
                <div><b class="mb-1"> </b> <small class="text-muted"> last updated: {user.coursesLastAttended[id]} </small></div>
                <div><b class="mb-1"> </b> <small class="text-muted"> expires on: {user.coursesExpiry[id]} </small></div>
                {/* <div><b class="mb-1"> </b> <small class="text-muted">  {(Math.ceil(Math.abs(new Date(user.coursesExpiry[id]) - new Date()) / (1000 * 60 * 60 * 24)))} days</small></div> */}
                <div><b class="mb-1"> </b> <small class="text-muted">
              
                  {(() => {
                  if (new Date(user.coursesExpiry[id]) < new Date()) {
                    varia = 'danger'
                    return (
                      '⏰'
                              )
                  }
                })()}
                </small></div>
                </Card>
                ))
                
                }
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
            </Card>
          </Col>  
        )
        
        )}

        </Row>
    </div>
    
  );
  
}

export default Progress;