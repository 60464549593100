import  {useState, useEffect}  from "react";
import Dashboard from "./Dashboard";
import {Image, Accordion, Button, FloatingLabel, Form, InputGroup, Alert, Card, Breadcrumb, Container, Row, Col, Modal} from 'react-bootstrap'
import { Redirect, useHistory} from "react-router-dom";
import { onAuthStateChanged, getAuth, createUserWithEmailAndPassword, signInWithEmailAndPassword } from 'firebase/auth';
import app from './firebase'
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'bootstrap/dist/css/bootstrap.min.css'
import {authentication} from './firebase';
import {BrowserRouter as Router, Switch, Route} from "react-router-dom";
import Carer from "./Carer";
// import Login from "./Login";
import Meds from "./Meds";
import Food from "./Food";
import Mood from "./Mood";
import Moodform from "./Moodform";
import Sleep from "./Sleep";
import Concerns from "./Concerns";
import Events from "./Events";
import Tasks from "./Tasks";
import { authorized, user } from './App';
// import { Link } from "react-router-dom";


export var user_id
export var role
export var ose
export var showb = false
// var gender

const db = firebase.firestore();
var userRef = db.collection('users')
// let authorized

const Login = () => {
  const auth = getAuth(app);
  const history = useHistory();
  // const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [username, setName] = useState(null)
  const [date, setDate] = useState(new Date());
  var [user_i, setUser] = useState(null);
  const [TVCHome, setTVCOption]=useState(null);
  // var [authorizeddd, setAuthorized] = useState(false);
  const [authenticated, setauthenticated] = useState(localStorage.getItem(localStorage.getItem("authenticated")|| false));
  const [phoneNum, setPhoneNum]=useState(null);
  const [workAddress, setWorkAdd]=useState(null);
  const [workPostCode, setWorkPostCode]=useState(null);
  const [homeAddress, setHomeAdd]=useState(null);
  const [homePostCode, setHomePostCode]=useState(null);
  const [option, setOption]=useState("student");
  const [gender, setGender]=useState(null);
  var [shower, setShow]=useState(true);

  // const users = [{ username: "Jane", password: "testpassword" }];
  // const handleSubmit = (e) => {
  //   e.preventDefault()
  //   const account = users.find((user) => user.username === username);
  //   if (account && account.password === password) {
  //       setauthenticated(true)
  //       localStorage.setItem("authenticated", true);
  //   }
  // };
  // authorizedd = authorizeddd
  user_id = user_i
  role = option
  ose = 'working'


  // useEffect(() => {
  //   localStorage.setItem(ose, 'working');
  // }, [role]);

  console.log('LoginUser:', user)
  console.log('role:', role)
  console.log('ose:', ose)
 

  // if (user == null){
  //   return <Redirect to="/login" />;
  // }

  const signUp = () => {
    if (username == null){
      alert('Looks like you missed an important information')
      return;
    }

    if (TVCHome == null){
      alert('Looks like you missed an important information')
      return;
    }

    if (date == null){
      alert('Looks like you missed an important information')
      return;
    }

    if (phoneNum == null){
      alert('Looks like you missed an important information')
      return;
    }

    if (homeAddress == null){
      alert('Looks like you missed an important information')
      return;
    }

    createUserWithEmailAndPassword(auth, email, password)
    .then((userCredential)=>{
      // signed up
      const user = userCredential.user
      console.log('user: ', user)

          var docData = {
            userName: username,
            userID: user.uid,
            house: TVCHome,
            gender: gender,
            DOB: date,
            phoneNum: phoneNum,
            workPostCode: workPostCode,
            workAddress: workAddress,
            homePostCode: homePostCode,
            homeAddress: homeAddress,
          }

          console.log(docData)
          db.collection('users').doc(user.uid).set(docData).then(() => {
            alert('Your account has been successfully created, Pls sign in..')
        });
      

      // TODO: redirect to careers
    })
    .catch((error)=>{
      const errorCode = error.code;
      const errorMessage = error.message
      alert(errorCode)
    })
  }

  // useEffect(() => {
  //   // getLifes()
  //   onAuthStateChanged(auth, (user) => {
  //     if (user) {
  //       // setAuthorized(true)
  //       // authorizedd=true
  //       console.log('user_i:', user_i)
  //       // history.push('/home');
  //       // window.location.reload();
  //       // setTwitterName(user.displayName)
  //       // setLife(window.sessionStorage.getItem("live"));
  //     }
  //     // openRoute(<Welcome />)
  //   });
  // }, []);



  const signIn = () => {
    console.log(option)
    console.log(email)


    if (option == "admin"){
      if (email != "jf@gmail.com" ){
        if(email != "ose@creditchek.africa"){
          setOption('student')
          return
        }
      }
      
    }


      signInWithEmailAndPassword(auth, email, password)
    .then((userCredential)=>{
      // signed in
      var user_id = userCredential.user.uid;
      console.log('user_signed: ', user_id)
      setUser(user_id)
      // setAuthorized(true)
      // // authorizedd=true
      // console.log(user)
      // this.props.history({
      //   state: ose // your data array of objects
      // })
      if(option === "teacher"){
        history.push('/tutor');
        window.location.reload();
      }else if(option === "student"){
        history.push('/home');
        window.location.reload();
      }else{
        history.push('/admin');
        window.location.reload();
      }
      // return <Redirect to="/home" />
      // navigate('/home')
      // alert('Successfully signed in')
      // 
    })
    .catch((error)=>{
      const errorCode = error.code;
      const errorMessage = error.message
      alert(errorCode)
    })
  
  }

  // select student or trainer
  // if (document.getElementById('staff-1') != null) {
  //   var studOrTeach = document.getElementById('group1').value;
  // }
  console.log('option: ', option)
  console.log('gender: ', gender)
  // console.log(document.querySelector('input[name="group1"]:checked'))
  // console.log('value1: ', document.getElementById('staff-1'))
  // console.log('value2: ', document.getElementById('group1'))
  // console.log('value3: ', document.querySelector('input[name=group1]:checked'))


  // gender
  // if (document.getElementById('staff-2').checked) {
  //  var  gender = document.getElementById('group2').value;
  // }

  function getName(fullname)
  {
    setName(fullname.target.value)
  }
// choose TVC home
function getTVCOption(tvcOption)
{
  setTVCOption(tvcOption.target.value)
}

function getOption(val)
{
  setOption(val.target.value)
}
function getGender(val)
{
  setGender(val.target.value)
}

function getPhoneNum(val)
{
  setPhoneNum(val.target.value)
}

function getWorkPostCode(val)
{
  setWorkPostCode(val.target.value)
}

function getWorkAdd(val)
{
  setWorkAdd(val.target.value)
}

function getHomePostCode(val)
{
  setHomePostCode(val.target.value)
}

function getHomeAdd(val)
{
  setHomeAdd(val.target.value)
}

  return (
    <div>
      {/* <Form style={{ margin: 20, borderRadius: 0}}>
        <Form.Group onChange={(e) => setEmail(e.target.value)} className="mb-3" controlId="formBasicEmail">
          <Form.Control type="email" placeholder="Email?" />
        </Form.Group>

        <Form.Group onChange={(e) => setPassword(e.target.value)} className="mb-3" controlId="formBasicPassword">
          <Form.Control type="password" placeholder="Password" />
        </Form.Group>
      </Form> */}

      <>

      {shower &&
        <Container  style={{ marginTop: 10}} className="py-5">
        <Row>
          <Col lg={6} md={6} sm={12} className="d-flex align-items-center">
            <div className="text-lg-start text-md-start text-center">
              <h1 className="font-primary color-primary">
              Empowering Care through Quality Training
              </h1>
              <p className="font-secondary color-secondary py-3">
              At Tees-Valley Care, we provide comprehensive training to carers 
              who are committed to giving compassionate care to their clients.{" "}
              </p>
          {/* <Button style={{ margin: 25, borderRadius:50}} variant="primary" onClick={startLearning}>
           Start Learning
          </Button>             */}
          </div>
          </Col>
          <Col lg={6} md={6} className="d-none d-lg-block">
            <Image src="./tvcimg.jpg" fluid />
          </Col>
        </Row>
      </Container> 
      }

      {/* <p style={{ marginBottom: 30, borderRadius: 0}}> Welcome Back </p> */}

      <FloatingLabel style={{ margin: 20, borderRadius: 0}}
        onChange={(e) => setEmail(e.target.value)}
        controlId="floatingInput"
        label="Email?"
        className="mb-3"
      >
        <Form.Control type="email" placeholder="name@example.com" />
      </FloatingLabel>
      <FloatingLabel style={{ margin: 20, borderRadius: 0}} onChange={(e) => setPassword(e.target.value)} controlId="floatingPassword" label="Password">
        <Form.Control type="password" placeholder="Password" />
      </FloatingLabel>
    </>

    <Form>
      {['radio'].map((type) => (
        <div key={`inline-${type}`} className="mb-3" onChange={getOption} style={{ marginTop: 10, borderRadius: 0}}>
          <Form.Check
            // checked
            inline
            label="I'm Student"
            name="group1"
            type="radio"
            // onChange={getOption}
            value="student"
            // id={`staff-1`}
          />
          <Form.Check
            inline
            label="I'm Trainer"
            name="group1"
            type="radio"
            value="teacher"
            // id={`staff-2`}
          />
          <Form.Check
            inline
            label="I'm Admin"
            name="group1"
            type="radio"
            value="admin"
            // id={`staff-3`}
          />
        
        </div>
      ))}
    </Form>


      <div>
        <Button style={{alignItems: 'center', backgroundColor: '#0174C3', color: '#FFFFFF', borderRadius:50, width: 150, height: 55, marginBottom: 50, marginTop: 30}}  onClick={signIn}>Log In</Button>
      </div> 

      <div style={{ marginTop: 30, marginBottom: 30}}>
        OR
      </div> 


      <div style={{ marginTop: 30, marginBottom: 30}}>
        Create Account
      </div> 

      <Form style={{ margin: 20, borderRadius: 0}}>
      <>

      <FloatingLabel style={{ margin: 20, borderRadius: 0}}
        onChange={(e) => setName(e.target.value)}
        controlId="floatingInput"
        label="Full Name?"
        className="mb-3"
      >
        <Form.Control type="name" placeholder="Full Name" />
      </FloatingLabel>
      <FloatingLabel style={{ margin: 20, borderRadius: 0}}
        onChange={(e) => setEmail(e.target.value)}
        controlId="floatingInput"
        label="Email?"
        className="mb-3"
      >
        <Form.Control type="email" placeholder="name@example.com" />
      </FloatingLabel>
      <FloatingLabel style={{ margin: 20, borderRadius: 0}} onChange={(e) => setPassword(e.target.value)} controlId="floatingPassword" label="Password">
        <Form.Control type="password" placeholder="Password" />
      </FloatingLabel>
    

      {/* <Row className="mb-3"> */}
      <FloatingLabel  style={{ margin: 20, borderRadius: 0}} onChange={getHomeAdd} label="Home Address">
        <Form.Control type="address" placeholder="Home Address" />
      </FloatingLabel>

      <FloatingLabel style={{ margin: 20, borderRadius: 0}} onChange={getHomePostCode} label="Home Postcode">
        <Form.Control type="address" placeholder="Home Postcode" />
      </FloatingLabel>
     {/* </Row> */}

     {/* <Row className="mb-3"> */}
     <FloatingLabel  style={{ margin: 20, borderRadius: 0}} onChange={getWorkAdd} label="Work Address">
        <Form.Control type="address" placeholder="Work Address" />
      </FloatingLabel>

      <FloatingLabel style={{ margin: 20, borderRadius: 0}} onChange={getWorkPostCode} label="Work Postcode">
        <Form.Control type="address" placeholder="Work Postcode" />
      </FloatingLabel>
     {/* </Row> */}

     <FloatingLabel style={{ margin: 20, borderRadius: 0}} onChange={getPhoneNum} controlId="phoneNumber" label="Phone Number">
        <Form.Control type="phone" placeholder="Phone Number" />
      </FloatingLabel>
      </>

      <Form.Group as={Col} controlId="formGridAddress1">
        <Form.Label>Date of Birth?</Form.Label>
        <Form.Control style={{marginTop: 5}}
                    type="date"
                    name="datepic"
                    placeholder="input event date"
                    value={date}
                    onChange={(e) => setDate(e.target.value)}
                  />
      </Form.Group>
        {/* <Form.Text muted>
         Date of Birth?
        </Form.Text> */}

        <Form>
        <Form.Label style={{marginTop: 20}}>Gender?</Form.Label>
          {['radio'].map((type) => (
            <div key={`inline-${type}`} className="mb-3" onChange={getGender} style={{ marginTop: 5, borderRadius: 0}}>
              <Form.Check
                // checked
                inline
                label="Female"
                name="group2"
                value="female"
                type="radio"
                id={`inline-${type}-1`}
              />
              <Form.Check
                inline
                label="Male"
                name="group2"
                value="male"
                type="radio"
                id={`inline-${type}-2`}
              />
            
            <Form.Check
                inline
                label="Other"
                name="group2"
                value="other"
                type="radio"
                id={`inline-${type}-2`}
              />
            

            <Form.Check
                inline
                label="Prefer not to say"
                name="group2"
                value="prefernottosay"
                type="radio"
                id={`inline-${type}-2`}
              />
            
            </div>
          ))}
        </Form>

        <Form>
          {/* <Form.Label style={{marginTop: 20}}>Only for TeesValley Care Staff</Form.Label> */}
          <Form.Select onChange={getTVCOption} style={{ marginTop: 10, marginBottom: 5,}} aria-label="Default select example">
              <option>TVC House?</option>
                <option value={'FH'}>Farm House</option>
                <option value={'WH'}>Wood House</option>
                <option value={'CH'}>Carney House</option>
                <option value={'AH'}>Alexander House</option>
                <option value={'nontvc'}>Non TVC Staff</option>
              
          </Form.Select>
        </Form>

      
      

      {/* <Form.Group className="mb-3" id="formGridCheckbox">
        <Form.Check type="checkbox" label="Check me out" />
      </Form.Group> */}

    </Form>

      <div >
        <Button style={{alignItems: 'center', backgroundColor: '#0174C3', color: '#FFFFFF', borderRadius:50, width: 150, height: 55, marginBottom: 50, marginTop: 30}} onClick={signUp}>Sign Up</Button>
      </div> 


    </div>


  )
};

export default Login;

// import React from "react";
// import {useHistory } from "react-router-dom"; //useNavigate,

// function Login() {
//     let history = useHistory();

//     // const navigate = useNavigate();

//     const navigateToCarer = () => {
//         history.push("/carer")
//        // navigate('/carer')
//     }

//     return (
//         <div>
//             <input type="text" placeholder="username"/>
//             <input type="text" placeholder="password"/>
//             <button onClick={() => {navigateToCarer()}}>Login
//             </button>
//         </div>
    
//     );
// }

// export default Login;