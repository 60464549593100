import React, {useState, useEffect} from "react";
import { Redirect } from "react-router-dom";
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import {Spinner, Accordion, Form, InputGroup, Alert, Card, Breadcrumb, Container, Row, Col, Modal} from 'react-bootstrap'
import firebase from 'firebase/compat/app';
import {user} from './App';
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";


const db = firebase.firestore();
let topic
let attendee
let instructor
let date
let time
let docId
let classSign
let aboutCourse
let attendeeID

function Moodform ({ authorized }) {
  const [courses, setCourses] = useState([]);
  const [isFetching, setIsFetching] = useState(true); 
  const [modalshow, setModalShow] = useState(false);
  const [email, setEmail]=useState(null);
  const [attendeeName, setAttendeeName] = useState(null)


  useEffect(() => {
    setTimeout(function () {
      console.log("Delayed for 5 second."); 
      setIsFetching(false); 
    }, 500);
  }, []);


  // get courses on page load
  useEffect(() => {
    getCourses()
    },[])

    if (isFetching) {
      return (
        <Box sx={{ display: "flex", justifyContent: "center" }}>
        <CircularProgress />
      </Box>
      );
    }

    if (!authorized){
        return <Redirect to="/login" />;
      }


      function getCourses(){
      // setFeeds([])
      // db.collection("surveys").where('sex', "==", sex).where('stage', "==", data3)
      db.collection("classes")
      .get()
      .then((snapshot) => {
        if (snapshot.empty) {
          alert('Sorry no available classes scheduled at the moment. Check back again')
        }else{
          console.log(snapshot.docs.map(doc => doc.data()))
          setCourses(snapshot.docs.map(doc => doc.data()));
        }
       });
    
      }

      const handleClose = () => setModalShow(false);
      const handleShow = (classs) => {
        attendee = classs.maxAttendee
        instructor = classs.instructor
        date = classs.date
        time = classs.time
        topic = classs.courseTitle
        // docId = classs.id
        aboutCourse = classs.about
        classSign = classs.classSign
        attendeeID = email+classSign
        setModalShow(true);
      }


      function bookClass(){
        // add classkey to class creation
        // copy classkey to attendee document to identify which class 
        var docData = {
          attendeeName: attendeeName,
          attendeeSeat: 'seat',
          uploadedDate: new Date().toLocaleString(),
          eventDate: date,
          eventTime: time,
          classSign: classSign,
          instructor: instructor,
          classTitle: topic,
          about: aboutCourse,
          maxAttendee: attendee,
          uid: user,
          email: email,
          attendee_id: attendeeID
         }
    
    
         db.collection('attendees').doc().set(docData).then(() => {
          console.log(" Course title + successfully created!");
          console.log(docData);
      });
    
      alert(" Congrats! You've been booked for this class!")
      setModalShow(false)
      }

      function getAttendeeName(val)
      {
        setAttendeeName(val.target.value)
      }

      function getEmail(val)
      {
        setEmail(val.target.value)
      }

  return  (
    <div>
        <Row xs={1} md={2} className="g-2" style={{ margin: 10, marginTop: 10}}>
        {courses.map((course, id) => (
          <Col>
            <Card variant="light" style={{ backgroundColor: '#F4FFF9', borderRadius:15, margin: 10, marginTop: 40, padding:20}}  className="d-grid gap-2">
            <div><b class="mb-1"> </b> <small class="text-muted"> date: {course.date} </small></div>
            <div><b class="mb-1"> </b> {course.courseTitle} <small class="text-muted">  </small></div>
            {/* <div><b class="mb-1">  </b> {course.about} </div> */}
            <div><b class="mb-1"> </b> <small class="text-muted"> instructor: {course.instructor} </small></div>
            <div><b class="mb-1"> </b> <small class="text-muted"> class size: {course.maxAttendee} students </small></div>
            {/* <Accordion defaultActiveKey="0" style={{ margin: 10}}>
              <Accordion.Item eventKey="0">
                <Accordion.Header>About Course</Accordion.Header>
                <Accordion.Body> */}
                <div><b class="mb-1"> </b> <small class="text-muted"> {course.about} </small></div>
                {/* </Accordion.Body>
              </Accordion.Item>
            </Accordion> */}
            <div style={{alignItems: 'center'}}>
              <Button style={{alignItems: 'center', backgroundColor: '#0174C3', color: '#FFFFFF', borderRadius:50, width: 150, height: 55, marginBottom: 10, marginTop: 25}} variant="#0174C3" onClick= {() => handleShow(course)} > Book Class </Button>
            </div>
            
            </Card>
          </Col>  
            )
        
        )}
        </Row>

        <Modal show={modalshow} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
          Book Your Seat: {attendee} Available
          {/* <div><b class="mb-1"> Course: </b> <small class="text-muted"> {topic} </small></div>
          <div><b class="mb-1"> Instructor: </b> <small class="text-muted"> {instructor} </small></div> */}
          {/* <div><b class="mb-1"> Book Your Seat: </b> <small class="text-muted"> {attendee} Available</small></div> */}
          {/* <div><b class="mb-1"> Date: </b> <small class="text-muted"> {date} </small></div>
          <div><b class="mb-1"> Time: </b> <small class="text-muted"> {time} </small></div> */}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Full Name</Form.Label>
              <Form.Control
                type="email"
                placeholder=" "
                onChange={getAttendeeName}
                autoFocus
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Email address</Form.Label>
              <Form.Control
                type="email"
                placeholder="name@example.com"
                onChange={getEmail}
                autoFocus
              />
            </Form.Group>
            
          </Form>
        </Modal.Body>
        <Modal.Footer>
          {/* <Button variant="secondary" onClick={handleClose}>
            Close
          </Button> */}
          <Button style={{ margin: 25, borderRadius:50}} variant="primary" onClick={bookClass}>
            Book Seat
          </Button>
        </Modal.Footer>
      </Modal>

    </div>
    
  );
  
}

export default Moodform;