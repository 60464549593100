import React from "react"
import { Redirect } from "react-router-dom";
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import {Form, InputGroup, Alert, Card, Breadcrumb, Container, Row, Col, Modal} from 'react-bootstrap'
import TimePicker from 'react-bootstrap-time-picker';

function Meds ({ authorized }) {
  if (!authorized){
    return <Redirect to="/login" />;
  }

  return  (
    <div class="container">
    <Form>
      <Form.Group className="mb-3" controlId="formBasicEmail" style={{ margin: 10 }}>
        <InputGroup hasValidation style={{ marginTop: 10, borderRadius: 0}}>
            {/* <InputGroup.Text id="inputGroupPrepend"> ♫ </InputGroup.Text> */}
            <Form.Control
              type="text"
              placeholder="medication name"
              aria-describedby="inputGroupPrepend"
              onChange={''}
              required
            />
            <Form.Control.Feedback type="invalid">
              Please input a meds name.
            </Form.Control.Feedback>
          </InputGroup>

          <InputGroup hasValidation style={{ marginTop: 10, borderRadius: 0}}>
            {/* <InputGroup.Text id="inputGroupPrepend"> ♫ </InputGroup.Text> */}
            <Form.Control
              type="text"
              placeholder="dose taken"
              aria-describedby="inputGroupPrepend"
              onChange={''}
              required
            />
            <Form.Control.Feedback type="invalid">
              Please input dose quantity taken.
            </Form.Control.Feedback>
          </InputGroup>

          
          <TimePicker start="00:00" end="23:30" step={30} style={{ marginTop: 10, borderRadius: 0}}/>
{/* radio */}

{['radio'].map((type) => (
        <div key={`inline-${type}`} className="mb-3" style={{ marginTop: 10, borderRadius: 0}}>
          <Form.Check
            inline
            label="Supervised by carer"
            name="group1"
            type={type}
            id={`inline-${type}-1`}
          />
          <Form.Check
            inline
            label="Without Carer supervision"
            name="group1"
            type={type}
            id={`inline-${type}-2`}
          />
        
        </div>
      ))}
       
      <Button style={{ margin: 0, borderRadius: 0}} variant="primary" onClick={''}>
        Submit
      </Button>
      
      </Form.Group>
    </Form>
    </div>
    
  );
  
}

export default Meds;