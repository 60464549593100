import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {Route, BrowserRouter as Router} from 'react-router-dom';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'bootstrap-css-only/css/bootstrap.min.css';
import 'mdbreact/dist/css/mdb.css';
import Login from "./Login";
import Dashboard from "./Dashboard";


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Router>
      <App />
      {/* <useNavigate replace to="/login"/> */}
      {/* <href="http://localhost:3000/login"/> */}
      {/* <Route index element={<App />} /> */}
      {/* <Route path="/login" component={ Login } /> */}
      {/* <Route path="/dashboard" element={<Dashboard />} /> */}
  </Router>
  
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
