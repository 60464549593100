import React, {useState, useEffect} from "react";
import { Redirect } from "react-router-dom";
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import {Accordion, Form, InputGroup, Alert, Card, Breadcrumb, Container, Row, Col, Modal} from 'react-bootstrap'
import TimePicker from 'react-time-picker';
import firebase from 'firebase/compat/app';
import {user} from './App';
import {useHistory } from "react-router-dom";

const db = firebase.firestore();
var courseRef = db.collection('courses').doc()
var classRef = db.collection('classes').doc()
var courseInfoFast

function Food ({ authorized }) {
  const history = useHistory();
  const [date, setDate] = useState(new Date());
  const [timeOption, setTimeOption] = useState('00:00');
  const [courses, setCourses] = useState([]);
  const [courseInfo, setCourseInfo] = useState([]);
  const [selectedCourseOption, setCourseOption]=useState(null);
  const [instructor, setInstructor]=useState(null);


console.log('time: ', timeOption)
   // get courses on page load
   useEffect(() => {
    getCourses()
    },[])


  // if (!authorized){
  //   return <Redirect to="/login" />;
  // }

    // go into the courses database
    // get the course data and populate the class
  function getCourseInfo(){
    console.log('user: ', user)
    if (user == null){
      alert('please sign in')
      history.push('/login');
      window.location.reload();
      // return <Redirect to="/login" />;
      return
  // return <Redirect to="/login" />;
}

    if(selectedCourseOption == null | selectedCourseOption == 'Choose Course'){
      alert('please select a Course option')
      return
    }

    if(date == null){
      alert('please select a date')
      return
    }

    console.log('timeOption: ', timeOption)

    db.collection('courses').where('courseTitle', "==", selectedCourseOption)
    .get()
    .then((snapshot) => {
      if (snapshot.empty) {
        alert('Please check, it appears this course has been deleted!')
      }else{
        console.log(snapshot.docs.map(doc => doc.data()))
        setCourseInfo(snapshot.docs.map(doc => doc.data()));
        courseInfoFast = snapshot.docs.map(doc => doc.data())
        console.log('courseInfoFast: ', courseInfoFast)
        scheduleClass()
      }
     });
  }

  function scheduleClass(){

    console.log('courseInfoFast2: ', courseInfoFast)
    console.log('courseTitle: ', courseInfoFast[0].courseTitle)
    console.log('courseSign: ', courseInfoFast[0].courseTitle + date + timeOption)
    var courseSign = courseInfoFast[0].courseTitle + date + timeOption
    var frequency = courseInfoFast[0].frequency
    courseSign = courseSign.replaceAll(/\s/g,'')
    courseSign = courseSign.replaceAll('-', '')
    var courseTitle = courseInfoFast[0].courseTitle

      var docData = {
        courseTitle: courseTitle, 
        about: courseInfoFast[0].about,
        maxAttendee: courseInfoFast[0].maxAttendee,
        date: date,
        time: timeOption,
        uploadedDate: new Date().toLocaleString(),
        classSign: courseSign,
        instructor: instructor,
        frequency: frequency,
        uid: user
       }
  
      classRef.set(docData).then(() => {
        console.log(" successfully created!");
        console.log(docData);
    }).then(() => {
      alert("Class schedule has been created successfully!")
      reload()
  })
  
    

  }

  function reload() {
    window.location.reload();
}


  function getCourses(){
    // setFeeds([])
    // db.collection("surveys").where('sex', "==", sex).where('stage', "==", data3)
    db.collection("courses")
    .get()
    .then((snapshot) => {
      if (snapshot.empty) {
        // alert('Sorry no courses available at the moment')
      }else{
        // console.log(snapshot.docs.map(doc => doc.data()))
        setCourses(snapshot.docs.map(doc => doc.data()));
      }
     });
  
    }

  function getCourseOption(courseOption)
  {
    setCourseOption(courseOption.target.value)
  }

  function getInstructor(val)
  {
    setInstructor(val.target.value)
  }
  
  return  (
    <div class="container" style={{ marginTop: 40, borderRadius: 0}}>
    <Form>

      <Form.Group className="mb-3" controlId="formBasicEmail" style={{ margin: 5, marginTop: 20, color: '#0020BC' }}>
        <Form.Control style={{marginTop: 5, color: '#0020BC'}}
                  type="date"
                  name="datepic"
                  placeholder="DateRange"
                  value={date}
                  onChange={(e) => setDate(e.target.value)}
                />

         <Form.Select onChange={getCourseOption} style={{ marginTop: 5, color: '#0020BC'}} aria-label="Default select example">
            <option>Select Course</option>
            {courses.map((course,id) => 
              <option value={course.courseTitle}>{course.courseTitle}</option>
            )}
            
          </Form.Select>
            <div style={{ marginTop: 10,  marginBottom: 10, color: '#0020BC'}}>
            <TimePicker style={{color: '#0020BC'}} onChange={setTimeOption} value={timeOption}  clearIcon="Clear" start="00:00" end="23:30" step={30} 
          />
            </div>
         

          <InputGroup style={{ marginTop: 5, color: '#0020BC'}} hasValidation>
            <Form.Control as="textarea" 
              rows={1}
              placeholder="Instructor?"
              aria-describedby="inputGroupPrepend"
              onChange={getInstructor}
              required
            />
            <Form.Control.Feedback type="invalid">
              Please input your concerns.
            </Form.Control.Feedback>
          </InputGroup>


          <Button style={{alignItems: 'center', backgroundColor: '#0174C3', color: '#FFFFFF', borderRadius:50, width: '150', height: 55, marginBottom: 50, marginTop: 40}} variant="#0174C3" onClick={getCourseInfo}>
            Schedule
          </Button>
          
      </Form.Group>
       
    
    </Form>
    </div>
    
  );
  
}

export default Food;