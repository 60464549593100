import React from "react"
import { Redirect } from "react-router-dom";
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';

function Mood ({ authorized }) {
  if (!authorized){
    return <Redirect to="/login" />;
  }

  return  (
    <div class="container">
      <ButtonGroup vertical center style={{ margin: 20, borderRadius:3}}>
      <Button variant="light">Sleep</Button>
      <Button variant="light">Mood</Button>
      </ButtonGroup>
    </div>
    
  );
  
}

export default Mood;
