import React, {useState, useEffect} from "react"
import { Redirect } from "react-router-dom";
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import {FloatingLabel, Accordion, Form, InputGroup, Alert, Card, Breadcrumb, Container, Row, Col, Modal} from 'react-bootstrap'
import TimePicker from 'react-bootstrap-time-picker';
import firebase from 'firebase/compat/app';
import { user } from './App';
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";


const db = firebase.firestore();
var classRef = db.collection('classes');
var attendeeRef = db.collection('attendees');
var userRef = db.collection('users');
let classSign

function Tasks ({ authorized }) {
  const [classes, setClasses] = useState([]);
  const [isFetching, setIsFetching] = useState(true); 
  const [modalshow, setModalShow] = useState(false);
  const [classSign, setClassSign] = useState(false);
  const [key, setKey] = useState(null); 

  useEffect(() => {
    setTimeout(function () {
      console.log("Delayed for 5 second."); 
      setIsFetching(false); 
      getClasses()
    }, 500);
  }, []);

   // get courses on page load
  //  useEffect(() => {
  //   getClasses()
  //   },[])

  const handleClose = () => setModalShow(false);
  const handleShow = (classSign) => {
    setClassSign(classSign)
    console.log('classSign:', classSign)
    setModalShow(true);
  }

    if (isFetching) {
      return (
        <Box sx={{ display: "flex", justifyContent: "center" }}>
        <CircularProgress />
      </Box>
      );
    }


  if (!authorized){
    return <Redirect to="/login" />;
  }

  function getClasses(){
    attendeeRef.where('uid', "==", user)
    .get()
    .then((snapshot) => {
      if (snapshot.empty) {
        // console.log(user)
        alert("You don't have any upcoming classes at the moment")
        return <Redirect to="/home" />;
      }else{
        setClasses(snapshot.docs.map((doc) =>({id:doc.id, ...doc.data()})));
      }
      });
  } 

  function formatDate(timestamp){
    var x=new Date(timestamp);
    var dd = x.getDate();
    var mm = x.getMonth()+1;
    var yy = x.getFullYear();
    // return dd +"/" + mm+"/" + yy;
    return yy +"-" + mm+"-" + dd;
 }
  
  
  function checkKey(classSign){
    // console.log('classSign: ', classSign)
    // console.log('key: ', key)
    if(key == null){
alert('input an attendance code after the class')
return
    }
    classRef.where('classSign', "==", classSign)
      .get()
      .then((snapshot) => {
        if (snapshot.empty) {
          alert('There seem to be an error with the attendance, Pls contact admin!')
        }else{
          // console.log(snapshot.docs.map((doc) => ({id: doc.id, ...doc.data()})))
          var attendenceKey = snapshot.docs.map((doc) => ({id: doc.id, ...doc.data()}))[0].attendenceKey
          if (key == attendenceKey){
           
            // take the course title and the date
            var courseTitle = snapshot.docs.map((doc) => ({id: doc.id, ...doc.data()}))[0].courseTitle
            var courseDate = snapshot.docs.map((doc) => ({id: doc.id, ...doc.data()}))[0].date
            var freq = snapshot.docs.map((doc) => ({id: doc.id, ...doc.data()}))[0].frequency
            var classSign = snapshot.docs.map((doc) => ({id: doc.id, ...doc.data()}))[0].classSign
            console.log(freq)
            console.log(courseDate)
            console.log(snapshot.docs.map((doc) => ({id: doc.id, ...doc.data()})))
            var ms = new Date(courseDate).getTime() + 86400000 * freq;
            var expiryday = new Date(ms);
            expiryday = formatDate(expiryday)
            console.log(expiryday)

            // find the user with userID
            userRef.doc(user).update({
              coursesAttended: firebase.firestore.FieldValue.arrayUnion(courseTitle),
              coursesLastAttended: firebase.firestore.FieldValue.arrayUnion(courseDate),
              coursesExpiry: firebase.firestore.FieldValue.arrayUnion(expiryday),
              classSign: firebase.firestore.FieldValue.arrayUnion(classSign)
          }, { merge: true })
            // update the coursesAttended and coursesLastAttended arrays
            alert('Your attendance has been recorded successfully!')
          }else{
            alert('Sorry, your attendance Key is wrong, contact your instructor!')
            }
        }
       });
   }

   function deleteTicket(){
    console.log('classSign', classSign)
    var attendee_query = db.collection('attendees').where('classSign','==', classSign);
        attendee_query.get().then(function(querySnapshot) {
            querySnapshot.forEach(function(doc) {
                console.log('doc: ', doc.ref)
                doc.ref.delete()
                setModalShow(false)
                alert('slot successfully deleted')
                setTimeout(function () {
                  window.location.reload();
                }, 500);

            })

    });
   }


  return  (
    <div>
        <Row xs={1} md={2} className="g-2" style={{ margin: 10, marginTop: 10}}>
        {classes.map((classs, id) => (
          <Col>
            <Card variant="light" onClick={()=>'handleShow(classs)'} style={{ backgroundColor: '#F4FFF9', borderRadius:15, margin: 10, marginTop: 40, padding:20}} className="d-grid gap-2">
            <div><b class="mb-1"> </b>  <small class="text-muted"> Ticket To: {classs.attendeeName} </small></div>
            <div><b class="mb-1"> </b> {classs.classTitle} <small class="text-muted">  </small></div>
            {/* <div><b class="mb-1">  </b> {classs.uploadedDate} </div> */}
            <div><b class="mb-1"> </b>  <small class="text-muted"> Event Date: {classs.eventDate}, {classs.eventTime} </small></div>
            <div><b class="mb-1"> </b>  <small class="text-muted">Instructor: {classs.instructor} </small></div>
            {/* <Accordion defaultActiveKey="0" style={{ margin: 10}}>
              <Accordion.Item eventKey="0">
                <Accordion.Header>About Course</Accordion.Header>
                <Accordion.Body> */}
                <div style={{ margin: 10}}>
                <div><b class="mb-1"> </b> <small class="text-muted">  {classs.about} </small></div>
                </div>
                 
                {/* </Accordion.Body>
              </Accordion.Item>
            </Accordion> */}
            <FloatingLabel style={{ margin: 10, borderRadius: 0}}
                onChange={(e) => setKey(e.target.value)}
                controlId="floatingInput"
                label="Attendance Key?"
                className="mb-3"
              >
                <Form.Control type="name" placeholder="Input Pass" />
                <Form.Text className="text-muted">
                Your attendance key will be given at the end of the class to confirm your attendance.
              </Form.Text>
              </FloatingLabel>

              <div style={{alignItems: 'center'}}>
                <Button style={{alignItems: 'center', backgroundColor: '#0174C3', color: '#FFFFFF', borderRadius:50, width: 200, height: 55, marginBottom: 0, marginTop: 25}} variant="#0174C3" onClick= {() => checkKey(classs.classSign)} > Submit Attendance </Button>
              </div>
              <div style={{alignItems: 'center'}}>
                <Button style={{alignItems: 'center', backgroundColor: '#0174C3', color: '#FFFFFF', borderRadius:50, width: 200, height: 55, marginBottom: 30, marginTop: 25}} variant="#0174C3" onClick= {() => handleShow(classs.classSign)} > Delete </Button>
              </div>
            {/* <Button style={{alignItems: 'center', backgroundColor: '#0174C3', color: '#FFFFFF', borderRadius:25, width: '30%', height: 55}} variant="#0174C3" onClick= {() => checkKey(classs.classSign)} > Completed </Button> */}
            </Card>
          </Col>
            )
        )}

      </Row>

      <Modal show={modalshow} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
          Are you sure you want to delete your slot? 
          {/* <div><b class="mb-1"> Course: </b> <small class="text-muted"> {topic} </small></div>
          <div><b class="mb-1"> Instructor: </b> <small class="text-muted"> {instructor} </small></div> */}
          {/* <div><b class="mb-1"> Book Your Seat: </b> <small class="text-muted"> {attendee} Available</small></div> */}
          {/* <div><b class="mb-1"> Date: </b> <small class="text-muted"> {date} </small></div>
          <div><b class="mb-1"> Time: </b> <small class="text-muted"> {time} </small></div> */}
          </Modal.Title>
        </Modal.Header>
       
        <Modal.Footer>
          {/* <Button variant="secondary" onClick={handleClose}>
            Close
          </Button> */}
          {/* <Button style={{ margin: 25, borderRadius:50}} variant="primary" onClick={bookClass}>
            Book Seat
          </Button> */}
              <div style={{alignItems: 'center'}}>
                <Button style={{alignItems: 'center', backgroundColor: '#0174C3', color: '#FFFFFF', borderRadius:50, width: 200, height: 55,}} variant="#0174C3" onClick= {() => deleteTicket()} > Delete </Button>
              {/* </div>
              <div style={{alignItems: 'center'}}> */}
                <Button style={{alignItems: 'center', backgroundColor: '#0174C3', color: '#FFFFFF', borderRadius:50, width: 200, height: 55,}} variant="#0174C3" onClick= {() => handleClose()} > No </Button>
              </div>
        </Modal.Footer>
      </Modal>
    </div>
    
    
  );
  
}

export default Tasks;