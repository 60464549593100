import React, {useState, useEffect} from "react"
import { Redirect } from "react-router-dom";
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import {FloatingLabel, Accordion, Form, InputGroup, Alert, Card, Breadcrumb, Container, Row, Col, Modal} from 'react-bootstrap'
import { doc, setDoc } from "firebase/firestore";
import firebase from 'firebase/compat/app';
import { user } from './App';

const db = firebase.firestore();
var classRef = db.collection('classes');


function Events ({ authorized }) {
  const [classes, setClasses] = useState([]);
  const [key, setKey] = useState([]);

   // get courses on page load
   useEffect(() => {
    getClasses()
    },[])

  if (!authorized){
    return <Redirect to="/login" />;
  }

 function uploadKey(classSign){
  console.log('classSign: ', classSign)
  console.log('key: ', key)
  classRef.where('classSign', "==", classSign)
    .get()
    .then((snapshot) => {
      if (snapshot.empty) {
        alert('There seem to be an error with the attendance, Pls contact admin!')
      }else{
        var id = snapshot.docs.map((doc) => ({id: doc.id, ...doc.data()}))[0].id
        classRef.doc(id).update({
              attendenceKey: key
          })
        alert('Attendance Key for this class has been set successfully!')

      }
     });
 }

function getClasses(){
  classRef.where('uid', "==", user)
  .get()
  .then((snapshot) => {
    if (snapshot.empty) {
      alert("You don't have any classes at the moment")
    }else{
      // console.log(snapshot.docs.map((doc) =>({id:doc.id, ...doc.data()})))
      setClasses(snapshot.docs.map((doc) =>({id:doc.id, ...doc.data()})));
    }
    });

}

  

  return  (
    <div>
        <Row xs={1} md={2} className="g-2" style={{ margin: 10, marginTop: 10}}>
        {classes.map((classs, id) => (
          <Col>
            <Card onClick={()=>'handleShow(classs)'} style={{ backgroundColor: '#F4FFF9', borderRadius:15, margin: 10, marginTop: 40, padding:20}}  className="d-grid gap-2">
            <div style={{ marginTop: 10}}><b class="mb-1"> </b> <small class="text-muted"> {classs.courseTitle} </small></div>
            {/* <div><b class="mb-1">  </b> {classs.about} </div> */}
            <div><b class="mb-1"> </b> <small class="text-muted"> {classs.date}, {classs.time} </small></div>
            <div><b class="mb-1"> </b> <small class="text-muted"> {classs.instructor} </small></div>
            <div><b class="mb-1"> </b> <small class="text-muted"> {classs.maxAttendee} seats available </small></div>
            {/* <Accordion style={{ margin: 10}}>
              <Accordion.Item eventKey="0">
                <Accordion.Header>About Course</Accordion.Header>
                <Accordion.Body> */}
                {/* </Accordion.Body>
              </Accordion.Item>
            </Accordion> */}
            
            <div><b class="mb-1"> </b> <small class="text-muted"> {classs.about} </small></div>

              <FloatingLabel style={{ margin: 10, borderRadius: 0}}
                onChange={(e) => setKey(e.target.value)}
                controlId="floatingInput"
                label="Attendence Key?"
                className="mb-3"
              >
                <Form.Control type="name" placeholder="Input Pass" />
              </FloatingLabel>
              <div><b class="mb-1"> </b> <small class="text-muted"> key: {classs.attendenceKey} </small></div>
            <div style={{alignItems: 'center', marginTop: 20, marginBottom: 20}}>
              <Button style={{alignItems: 'center', backgroundColor: '#0174C3', color: '#FFFFFF', borderRadius:50, width: '150', height: 55}} onClick= {() => uploadKey(classs.classSign)} variant="#67DFFF"  > Upload Key </Button>
            </div>
            </Card>         
          </Col>
            )
        )}

        </Row>
    </div>
    
  );
  
}

export default Events;