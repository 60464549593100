import React, {useState} from "react";
import { Redirect } from "react-router-dom";
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import {Form, InputGroup, Alert, Card, Breadcrumb, Container, Row, Col, Modal} from 'react-bootstrap'
import { doc, setDoc } from "firebase/firestore";
import firebase from 'firebase/compat/app';
import { user } from './App';

const db = firebase.firestore();
var courseRef = db.collection('courses').doc()

function Concerns ({ authorized }) {
  const [title, setTitle] = useState(null)
  const [about, setAbout] = useState(null)
  const [maxAmount, setMaxAmount] = useState(null)
  const [freq, setFrequency] = useState(null)
  // const [date, setDate] = useState(new Date())

  if (!authorized){
    return <Redirect to="/login" />;
  }

  function getTitle(val)
  {
    setTitle(val.target.value)
  }

  function getAbout(val)
  {
    setAbout(val.target.value)
  }

  function getMaxAmount(val)
  {
    setMaxAmount(val.target.value)
  }

  function getFrequency(val)
  {
    setFrequency(val.target.value)
  }

  function uploadCourse(){

    if (title == null){
      alert('Looks like you missed an important information')
      return;
    }

    if (about == null){
      alert('Looks like you missed an important information')
      return;
    }

    if (freq == null){
      alert('Looks like you missed an important information')
      return;
    }

    if (maxAmount == null){
      alert('Looks like you missed an important information')
      return;
    }

    var courseSign = title + new Date()
    courseSign = courseSign.replaceAll(' ', '')

    var docData = {
      courseTitle: title,
      about: about,
      frequency: freq,
      maxAttendee: maxAmount,
      // nextDate: date,
      uploadedDate: new Date().toLocaleString(),
      uploader: user,
      courseSign: courseSign
     }

    courseRef.set(docData).then(() => {
      console.log(" Course title + successfully created!");
      console.log(docData);
  }).then(() => {
    alert(title + " has been successfully created!")
    reload()
})
}

  function reload() {
    window.location.reload();
}

  return  (
    <div class="container" style={{ marginTop: 30, borderRadius: 0}}>
    <Form>
      <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1" style={{ margin: 5}}>
        <InputGroup style={{ marginTop: 5}} hasValidation>
            <Form.Control as="textarea"
              rows={2}
              placeholder="Course Title?"
              aria-describedby="inputGroupPrepend"
              onChange={getTitle}
              required
            />
            <Form.Control.Feedback type="invalid">
              Please input your concerns.
            </Form.Control.Feedback>
          </InputGroup>

          <InputGroup style={{ marginTop: 5}} hasValidation>
            <Form.Control as="textarea"
              rows={3}
              placeholder="What's the Course about?"
              aria-describedby="inputGroupPrepend"
              onChange={getAbout}
              required
            />
            <Form.Control.Feedback type="invalid">
              Please input your concerns.
            </Form.Control.Feedback>
          </InputGroup>

          <InputGroup style={{ marginTop: 5}} hasValidation>
            <Form.Control as="textarea"
              rows={1}
              placeholder="Max. amount of Attendees"
              aria-describedby="inputGroupPrepend"
              onChange={getMaxAmount}
              required
            />
            <Form.Control.Feedback type="invalid">
              Please input the max. number of attendees for this course
            </Form.Control.Feedback>
          </InputGroup>

          <Form.Select onChange={getFrequency} style={{marginTop: 5, color: '#0020BC'}} aria-label="Default select example">
            <option>Frequency</option>
            {/* <option value="1">As Needed</option> */}
            <option value="30">Monthly</option>
            <option value="124">Quarterly</option>
            <option value="183">Bi-Annually</option>
            <option value="365">Annually</option>
            <option value="730">In 2 Years</option>
            <option value="1095">In 3 Years</option>
          </Form.Select>

          <div className="App">
     
              {/* <Form.Control style={{ marginTop: 5}}
                type="date"
                name="datepic"
                placeholder="DateRange"
                value={date}
                onChange={(e) => setDate(e.target.value)}
              /> */}
          
    </div>

          <Button style={{alignItems: 'center', backgroundColor: '#0174C3', color: '#FFFFFF', borderRadius:50, width: 150, height: 55, marginBottom: 50, marginTop: 25}} variant="#0174C3" onClick={uploadCourse}>
              Submit
          </Button>
      </Form.Group>
       
  
    </Form>
    </div>
    
  );
  
}

export default Concerns;