import React from "react"
import { Redirect } from "react-router-dom";
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import {Form, InputGroup, Alert, Card, Breadcrumb, Container, Row, Col, Modal} from 'react-bootstrap'

function Sleep ({ authorized }) {
  if (!authorized){
    return <Redirect to="/login" />;
  }

  return  (
    <div class="container" style={{ marginTop: 20, borderRadius: 0}}>
    <Form>
    <Form.Label>How relaxing was your sleep?</Form.Label>
      {['radio'].map((type) => (
        <div key={`inline-${type}`} className="mb-3" style={{ marginTop: 10, borderRadius: 0}}>
        
          <Form.Check
            inline
            label="very relaxing"
            name="group1"
            type={type}
            id={`inline-${type}-1`}
          />
          <Form.Check
            inline
            label="relaxing"
            name="group1"
            type={type}
            id={`inline-${type}-2`}
          />

          <Form.Check
            inline
            label="not so relaxing"
            name="group1"
            type={type}
            id={`inline-${type}-2`}
          />
        
        </div>
      ))}


      <Button style={{ margin: 10, borderRadius: 0}} variant="primary" onClick={''}>
        Submit
      </Button>
    </Form>
    </div>
    
  );
  
}

export default Sleep;