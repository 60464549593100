import React from "react"
import { Redirect } from "react-router-dom";
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import {Form, InputGroup, Alert, Card, Breadcrumb, Container, Row, Col, Modal} from 'react-bootstrap'

function Report ({ authorized }) {
  if (!authorized){
    return <Redirect to="/login" />;
  }

  return  (
    <div class="container">
    <Form>
       
      <Button style={{ margin: 0, borderRadius: 0}} variant="primary" onClick={''}>
        Submit
      </Button>
    </Form>
    </div>
    
  );
  
}

export default Report;