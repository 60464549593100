import React, {useState, useEffect} from "react";
import { Redirect } from "react-router-dom";
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import {Spinner, Accordion, Form, InputGroup, Alert, Card, Breadcrumb, Container, Row, Col, Modal} from 'react-bootstrap'
import firebase from 'firebase/compat/app';
import user from './App';
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";


const db = firebase.firestore();

function AllCourses ({ authorized }) {
  const [courses, setCourses] = useState([]);
  const [isFetching, setIsFetching] = useState(true); 

  useEffect(() => {
    setTimeout(function () {
      console.log("Delayed for 5 second."); 
      setIsFetching(false); 
    }, 500);
  }, []);


  // get courses on page load
  useEffect(() => {
    getCourses()
    },[])

    if (isFetching) {
      return (
        <Box sx={{ display: "flex", justifyContent: "center" }}>
        <CircularProgress />
      </Box>
      );
    }

    if (!authorized){
        return <Redirect to="/login" />;
      }


      function getCourses(){
      // setFeeds([])
      // db.collection("surveys").where('sex', "==", sex).where('stage', "==", data3)
      db.collection("courses")
      .get()
      .then((snapshot) => {
        if (snapshot.empty) {
          alert('Sorry no available classes scheduled at the moment. Check back again')
        }else{
          console.log(snapshot.docs.map(doc => doc.data()))
          setCourses(snapshot.docs.map(doc => doc.data()));
        }
       });
    
      }

  return  (
    <div>
        <Row xs={1} md={2} className="g-2" style={{ margin: 10, marginTop: 10}}>
        {courses.map((course, id) => (
          <Col>
            <Card variant="light" style={{ backgroundColor: '#F4FFF9', borderRadius:15, margin: 10, marginTop: 40, padding:20}}  className="d-grid gap-2">
            <div><b class="mb-1"> </b> {course.courseTitle} <small class="text-muted">  </small></div>
            {/* <div><b class="mb-1"> </b> <small class="text-muted"> date: {course.date} </small></div> */}
            {/* <div><b class="mb-1"> </b> <small class="text-muted"> instructor: {course.instructor} </small></div> */}
            <div><b class="mb-1"> </b> <small class="text-muted"> class size: {course.maxAttendee} students </small></div>
            {/* <Accordion defaultActiveKey="0" style={{ margin: 10}}>
              <Accordion.Item eventKey="0">
                <Accordion.Header>About Course</Accordion.Header>
                <Accordion.Body> */}
                <div><b class="mb-1"> </b> <small class="text-muted">   {course.about}</small></div>
                {/* </Accordion.Body>
              </Accordion.Item>
            </Accordion> */}
            {/* <div style={{alignItems: 'center'}}>
              <Button style={{alignItems: 'center', backgroundColor: '#0174C3', color: '#FFFFFF', borderRadius:50, width: 150, height: 55, marginBottom: 50, marginTop: 25}} variant="#0174C3" onClick= {'checkKey'} > Book Class </Button>
            </div> */}
            
            </Card>
          </Col>  
            )
        
        )}

        </Row>
        

        
    </div>
    
  );
  
}

export default AllCourses;