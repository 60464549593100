import React, {useState, useEffect} from "react"
import { Redirect } from "react-router-dom";
// import Button from 'react-bootstrap/Button';
// import ButtonGroup from 'react-bootstrap/ButtonGroup';
import ListGroup from 'react-bootstrap/ListGroup';
import {Accordion, FloatingLabel, Form, InputGroup, Alert, Card, Breadcrumb, Container, Row, Col, Modal} from 'react-bootstrap'
import firebase from 'firebase/compat/app';
import Button from 'react-bootstrap/Button';
import ClassPrev from "./ClassPrev";
import { upload } from "@testing-library/user-event/dist/upload";
import { user, oseapp } from './App';
import {useHistory} from "react-router-dom";
import {role, authorizedd, user_id, ose} from './Login';

const db = firebase.firestore();
var courseRef = db.collection('courses')
var classRef = db.collection('classes')
let topic
let attendee
let instructor
let date
let time
let docId
let classSign
let teacher
// var showTeachV
// var showStudV
var studBollean
var teachBollean
// const { ose } = this.props.location

function Tutor ({authorized}) {
  // console.log('teach: ', teachBollean)
  // console.log('stud: ', studBollean)
  const history = useHistory();
  const [courses, setCourses] = useState([]);
  const [classes, setClasses] = useState([]);
  const [selectedCourseOption, setCourseOption]=useState(null);
//   var [showStud, setShowStud]=useState(showStudV);
//   var [showTeach, setShowTeach]=useState(showTeachV);
  const [show, setShow]=useState(false);
  const [selection, selectClass] = useState([]);
  const [modalshow, setModalShow] = useState(false);
  const [attendeeName, setAttendeeName] = useState(null)
  const [about, setAbout] = useState(null)

  // const { ose } = window.location
//   console.log('role:', role)
//   console.log('oseCar:', oseapp)
//   console.log('ose:', ose)

//   if(role === undefined){
//     showStudV = false
//     showTeachV = true
//     teacher = false
//   }else{
//     showTeachV = false
//     showStudV = true
//     teacher = true
//   }

//   if(teacher){
// setShowTeach(false)
// setShowStud(true)
//   }else{
//     setShowTeach(true)
// setShowStud(false)
//   }
  // findHome = 'tutor'
  const handleClose = () => setModalShow(false);
  const handleShow = (classs) => {
    attendee = classs.maxAttendee
    instructor = classs.instructor
    date = classs.date
    time = classs.time
    topic = classs.courseTitle
    docId = classs.id
    classSign = classs.classSign
    setModalShow(true);
  }

  // TODO: get it to go to the next page successfully
  // console.log('authorizedd: ', authorizedd)
  console.log('authorized: ', authorized)
  // console.log('user_id: ', user_id)
  console.log('user: ', user)

  // get courses on page load
  useEffect(() => {
    getCourses()
    },[])


    // if (!authorized){
    //   return <Redirect to="/login" />;
    // }

// useEffect(() => {
//   if (authorized !== true){
//     return <Redirect to="/login" />;
//   }
// }, []);

  // function bookClass(classs){
  //   selectClass(classs)
  //   // console.log(classs)
  // }

  function getCourses(){
    // setFeeds([])
    // db.collection("surveys").where('sex', "==", sex).where('stage', "==", data3)
    courseRef
    .get()
    .then((snapshot) => {
      if (snapshot.empty) {
        alert('Sorry no courses available at the moment')
      }else{
        console.log(snapshot.docs.map(doc => doc.data()))
        setCourses(snapshot.docs.map(doc => doc.data()));
      }
     });
  
  }

  function bookClass(){
    // add classkey to class creation
    // copy classkey to attendee document to identify which class 
    var docData = {
      attendeeName: attendeeName,
      attendeeSeat: 'seat',
      uploadedDate: new Date().toLocaleString(),
      classSign: classSign
     }

     db.collection('attendees').doc(docId).set(docData).then(() => {
      console.log(" Course title + successfully created!");
      console.log(docData);
  });

  alert(" Congrats! You've been booked for this class!")
  }


  function getClasses(){

      if (user == null){
        alert('please sign in')
        history.push('/login');
        window.location.reload();
        return
    // return <Redirect to="/login" />;
  }
    // setFeeds([])
    // console.log('selectedCourseOption: ', selectedCourseOption)
    if(selectedCourseOption == null | selectedCourseOption == 'Select Course'){
      alert('please select a Course option')
      return
    } 

    classRef.where('courseTitle', "==", selectedCourseOption)
    // .where('date', ">=", 'today')
    .get()
    .then((snapshot) => {
      if (snapshot.empty) {
        console.log('selectedCourseOption: ', selectedCourseOption)
        alert('Please check back, no classes are available for this course at the moment')
        // if (teacher){
        //   setShowTeach(false)
        // }else{
        //   setShowStud(false)
        // }
        // showStud = true
        setShow(false)
        
        
        
      }else{
        // showStud = false
        console.log('selectedCourseOption: ', selectedCourseOption)
        // console.log(snapshot.docs.map((doc) =>({id:doc.id, ...doc.data()})))
        setClasses(snapshot.docs.map((doc) =>({id:doc.id, ...doc.data()})));
        //  if (teacher){
        //   setShowTeach(true)
        //  }else{
        //    setShowStud(true)
        // }
        setShow(true)
      }
     });
  
  }

  function getCourseOption(courseOption)
  {
    setCourseOption(courseOption.target.value)
  }

  function getAttendeeName(val)
  {
    setAttendeeName(val.target.value)
  }

  setTimeout(function () {
    console.log("Delayed for 5 second."); 
    if (user == null){
      window.location.replace("https://tvctraining.co.uk/login");
    }  }, 4000);

  // const alertClicked = () => {
  //   alert('You clicked the third ListGroupItem');
  // };

  return  (
    <div class="container" style={{ marginTop: 20, borderRadius: 0}}>
      <FloatingLabel style={{color: '#0020BC'}} controlId="floatingSelect" label="click here to find your next class">
      <Form.Select onChange={getCourseOption} style={{ marginTop: 20, marginBottom: 5, color: '#0020BC'}} aria-label="Default select example">
            <option>Select Course</option>
            {courses.map((course,id) => 
              <option value={course.courseTitle}>{course.courseTitle}</option>
            )}
            
      </Form.Select>
      </FloatingLabel>
      {/* <Form.Text id="passwordHelpBlock" muted>
         click the Search button to find all the Classes for that Course.
      </Form.Text> */}

      <Button style={{alignItems: 'center', backgroundColor: '#0174C3', color: '#FFFFFF', borderRadius:50, width: 150, height: 55, marginBottom: 50, marginTop: 20}} variant="#0174C3" onClick={getClasses}>
      search
      </Button>


      {show &&
        <div>
        <Row xs={1} md={2} className="g-2" style={{ margin: 10, marginTop: 10}}>
        {classes.map((classs, id) => (
          <Col>
            <Card variant="light" style={{ margin: 10, marginTop: 40, padding:20}}  className="d-grid gap-2">
            <div><b class="mb-1"> </b>  {classs.courseTitle}</div>
            {/* <div><b class="mb-1">  </b> {classs.about} </div> */}
            <div><b class="mb-1"> </b> <small class="text-muted"> {classs.date}, {classs.time} </small></div>
            <div><b class="mb-1"> </b> <small class="text-muted"> {classs.instructor} </small></div>
            <div><b class="mb-1"> </b> <small class="text-muted"> {classs.maxAttendee} seats available </small></div>
            <Accordion style={{ margin: 10}}>
              <Accordion.Item eventKey="0">
                <Accordion.Header>About Course</Accordion.Header>
                <Accordion.Body>
                  {classs.about}
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
            {/* <div><b class="mb-1"> </b> <small class="text-muted"> {classs.classSign} </small></div> */}
            <Button style={{ margin: 25, borderRadius:50}} onClick= {() => handleShow(classs)} variant="primary"  > Book Class </Button>
            </Card>
            </Col>
            )
        )}

        </Row>
        </div>
      }

    {!show && 
      <ListGroup style={{ marginTop: 30}}>
        {/* <ListGroup.Item action href="http://localhost:3000/meds">Medication</ListGroup.Item> */}
        <ListGroup.Item action href="https://tvctraining.co.uk/instructorclasses">My Classes</ListGroup.Item>
        <ListGroup.Item action href="https://tvctraining.co.uk/scheduleclass">Schedule Class</ListGroup.Item>
        <ListGroup.Item action href="https://tvctraining.co.uk/allclasses">Available Classes</ListGroup.Item>
        <ListGroup.Item action href="https://tvctraining.co.uk/allcourses">Tees-Valley Care Courses</ListGroup.Item>
        {/* <ListGroup.Item action href="https://bestplan.care/createcourses">Create Course</ListGroup.Item> */}
      </ListGroup>
      }

      <Modal show={modalshow} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
          Book Your Seat: {attendee} Available
          {/* <div><b class="mb-1"> Course: </b> <small class="text-muted"> {topic} </small></div>
          <div><b class="mb-1"> Instructor: </b> <small class="text-muted"> {instructor} </small></div> */}
          {/* <div><b class="mb-1"> Book Your Seat: </b> <small class="text-muted"> {attendee} Available</small></div> */}
          {/* <div><b class="mb-1"> Date: </b> <small class="text-muted"> {date} </small></div>
          <div><b class="mb-1"> Time: </b> <small class="text-muted"> {time} </small></div> */}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Full Name</Form.Label>
              <Form.Control
                type="email"
                placeholder=" "
                onChange={getAttendeeName}
                autoFocus
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Email address</Form.Label>
              <Form.Control
                type="email"
                placeholder="name@example.com"
                autoFocus
              />
            </Form.Group>
            
          </Form>
        </Modal.Body>
        <Modal.Footer>
          {/* <Button variant="secondary" onClick={handleClose}>
            Close
          </Button> */}
          <Button style={{ margin: 25, borderRadius:50}} variant="primary" onClick={bookClass}>
            Book Seat
          </Button>
        </Modal.Footer>
      </Modal>

      {/* <React.Fragment>
        <ClassPrev selection={selection}/>
      </React.Fragment> */}


    </div>
    
  );
  
}

export default Tutor;
